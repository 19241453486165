import styled from "styled-components"

export const Button = styled.button.attrs(props => ({}))`
  ${props => (props.small ? "padding: .5rem 1.2rem" : "padding: .8rem 1.2rem")};
  background-color: #007099;
  color: #fff;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  ${props =>
    props.small
      ? "font-size: 18px; line-height: 30px;"
      : "font-size: 22px; line-height: 30px"};
  font-weight: 700;
`
